import React from 'react';
import { makeStyles,withStyles } from '@material-ui/core/styles';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { Helmet } from 'react-helmet';
import {
    Button,
    Card,
    Grid
  } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import QRCode from 'qrcode.react'
import Pusher from 'pusher-js'
  const useStyles = makeStyles((theme) => ({
    card: {
        borderRadius: '24px',
        padding: 20,
    },
    text: {
        margin: 0,
    },
    image: {
        width:"100%",
    },
    section: {
        padding: 10,
    },
    icon: {
        paddingLeft: 5,
    },
}))

const ColorButton = withStyles((theme) => ({
    root: {
      borderRadius: '24px',
      color: theme.palette.getContrastText(green[500]),
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700],
      },
    },
  }))(Button);

export default function ModalProduct({qrdata,id,setOpen,img,name,price,descripcion,link }) {
    const classes = useStyles();
    
   React.useEffect(() => {
    var pusher = new Pusher('0e9db7d72da010ceff37', {
        cluster: 'us2'
    });
  
    var channel = pusher.subscribe('payment');
    channel.bind(id, function(data) {
        if (data.message === "success") {
            alert("El pago se a sido realizado de manera satisfactoria");
        }
        console.log(data)
      });
   },[])

    return(
        <Card className={classes.card}>
            <Helmet>
                <title>{name}</title>
                <meta name="description" content={descripcion} />
            </Helmet>
        <Grid container alignItems="center" justifyContent="center">
        <QRCode value={qrdata} />
        </Grid>
        </Card>
    )
}
