import React from "react";
import ReactDOM from "react-dom";
import { App } from "app";
import TiendaBody from "TiendaBody";



ReactDOM.render(
  <TiendaBody></TiendaBody>,
  document.getElementById("root")
);
